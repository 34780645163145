import React from 'react';

const Appbook = () => {
  return (
    <>
      <div className='textboox2' id='textbook2'>
        <div className="container mb-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <p className='texttitle text-center fs-2 mb-3'>Our High-End App Development Services</p>
              <p className='fs-5 textpara mb-2'>
              We offer full-cycle mobile app development services using technologies like React Native, Swift, and Kotlin. From ideation and design to deployment and ongoing support, we build high-performance, secure, and scalable mobile applications tailored to your needs. Our process ensures seamless integration, user-friendly interfaces, and apps that grow with your business.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appbook;
