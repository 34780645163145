import React from 'react';

const Commitment = () => {
  return (
    <>
      <div className='commitment-section' id='commitment'>
        <div className="container mb-5">
          <div className="card shadow-lg border-0">
            <div className="card-body">
              <p className='texttitle text-center fs-2  mb-3'>Our Commitment</p>
              <p className='fs-5 textpara'>
                At Trivinsai, we are committed to delivering expert IT consultations, robust infrastructure setups, and reliable network solutions. We prioritize cybersecurity to protect your data while providing essential electronics, office supplies, and unique packing services. Our goal is to ensure your business operates efficiently and professionally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commitment;
