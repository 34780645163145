import React from 'react';


const Webbook = () => {
  return (
    <>





<div className='textboox2' id='textbook2'>
      <div className="container mb-5">
        <div className="card shadow-sm">
          <div className="card-body">
            <p className='texttitle  text-center fs-2 mb-3'>Our high-end Web Development Services</p>
            <p className='fs-5 textpara mb-2'>
            We provide full-cycle web development services using the MERN stack. From initial concept and design to deployment and ongoing support, we build dynamic, secure, and scalable web applications tailored to your business needs. Our process ensures a high-quality, responsive solution that evolves with your requirements and delivers optimal performance.
            </p>
          </div>
        </div>
      </div>
    </div>



     
    </>
  );
};

export default Webbook;
